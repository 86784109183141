import React from "react";

const PanelWelcome = () => {
  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
      <h1 className="highlight-title-xl">Bienvenido al Panel</h1>
      <p className="highlight-title-l">
        ¡Aquí encontrarás toda la información que necesitas!
      </p>
    </div>
  );
};

export default PanelWelcome;
