import React from "react";
import { Link } from "react-router-dom";

const MailToButton = ({ mailTo }) => {
  return (
    <React.Fragment>
      <Link
        className="link"
        to="#"
        aria-label="Enviar Email "
        onClick={(e) => {
          window.location.href = "mailto:" + mailTo;
          e.preventDefault();
        }}
      >
        {mailTo}
      </Link>
    </React.Fragment>
  );
};

export default MailToButton;
