import logo from "../../assets/img/logo.webp";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { BiNews, BiCalendarEvent, BiHome } from "react-icons/bi";
import {
  RiMenuFoldFill,
  RiMenuUnfoldFill,
  RiLogoutBoxRLine,
} from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";

import PanelNews from "../panel/panelNews";
import PanelEvent from "../panel/panelEvent";
import PanelCourse from "../panel/panelCourse";
import PanelWelcome from "../panel/panelWelcome";
import { fetchLogoutData } from "../../redux/thunks/loginThunk";
import { useDispatch } from "react-redux";

const Panel = () => {
  const menu = useRef();
  const nav = useRef();
  const sidebar = useRef();
  const header = useRef();
  const panel = useRef();
  const [selectedOption, setSelectedOption] = useState("home");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickHideMenu = () => {
    header.current.classList.add("panel-header--move-left");
    sidebar.current.classList.add("panel-sidebar--show");
    menu.current.classList.remove("panel-icon--close");
    menu.current.classList.add("panel-icon--display");
    panel.current.classList.remove("panel-container--full");
  };

  const handleClickShowMenu = () => {
    header.current.classList.remove("panel-header--move-left");
    sidebar.current.classList.remove("panel-sidebar--show");
    menu.current.classList.remove("panel-icon--display");
    menu.current.classList.add("panel-icon--close");
    panel.current.classList.add("panel-container--full");
    window.scrollTo(0, 0);
  };

  const handleLogout = async (event) => {
    const auth = await dispatch(fetchLogoutData());
    if (auth.error === undefined) {
      localStorage.removeItem("email");
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      let path = "../acceso";
      navigate(path);
    }
  };

  const handleClick = async (event) => {
    handleClickHideMenu();
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    document.title = "Panel";
    if (
      Cookies.get("access_token") === undefined ||
      Cookies.get("refresh_token") === undefined
    ) {
      let path = "../acceso";
      navigate(path);
    }
  }, []);

  return (
    <div className="">
      <div className="bg-light fixed-top">
        <div className="row">
          <div className="col-3">
            <button
              ref={menu}
              className="secondary-large-icon panel-icon--close panel-icon--hide ps-3"
              onClick={handleClickShowMenu}
              aria-label="Mostrar Menu"
            >
              <RiMenuUnfoldFill />
            </button>
          </div>
          <div className="col-9 d-flex d-flex--right d-flex--direction-row pe-3">
            <p className="paragraph-text px-3" style={{ margin: 0 }}>
              Bienvenido {localStorage.getItem("email")}
            </p>
            <div>
              <button
                className="secondary-large-icon "
                onClick={handleLogout}
                aria-label="Salir de Panel"
              >
                <RiLogoutBoxRLine />
              </button>
            </div>
          </div>
        </div>
      </div>
      <header className="panel-header h-100  bg-light" ref={header}>
        <button
          className="secondary-large-icon panel-icon--display "
          onClick={handleClickHideMenu}
          aria-label="Esconder Menu"
        >
          <RiMenuFoldFill />
        </button>
        <nav className="nav" ref={nav}>
          <div className=" d-flex d-flex--direction-column">
            <img src={logo} alt="Logo" className="nav__l-logo" />
            <div className="panel-sidebar" ref={sidebar}>
              <div>
                <ul className="list-group" onClick={handleClick}>
                  <li
                    className={`list-group-item list-group-item-action bg-light text-center paragraph-text ${
                      selectedOption === "home" ? "active" : ""
                    }`}
                    onClick={() => handleOptionClick("home")}
                  >
                    <BiHome className="secondary-large-icon"> </BiHome> Inicio
                  </li>
                  <li
                    className={`list-group-item list-group-item-action bg-light text-center paragraph-text ${
                      selectedOption === "news" ? "active" : ""
                    }`}
                    onClick={() => handleOptionClick("news")}
                  >
                    <BiNews className="secondary-large-icon"> </BiNews> Noticias
                  </li>
                  <li
                    className={`list-group-item list-group-item-action bg-light text-center paragraph-text ${
                      selectedOption === "events" ? "active" : ""
                    }`}
                    onClick={() => handleOptionClick("events")}
                  >
                    <BiCalendarEvent className="secondary-large-icon"></BiCalendarEvent>{" "}
                    Eventos
                  </li>
                  <li
                    className={`list-group-item list-group-item-action bg-light text-center paragraph-text ${
                      selectedOption === "courses" ? "active" : ""
                    }`}
                    onClick={() => handleOptionClick("courses")}
                  >
                    <FaChalkboardTeacher className="secondary-large-icon"></FaChalkboardTeacher>{" "}
                    Cursos
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="d-flex d-flex--center px-3">
        <div
          className="panel-container panel-container--full w-100"
          ref={panel}
          onClick={handleClick}
        >
          {selectedOption === "home" && <PanelWelcome></PanelWelcome>}
          {selectedOption === "news" && <PanelNews></PanelNews>}
          {selectedOption === "events" && <PanelEvent></PanelEvent>}
          {selectedOption === "courses" && <PanelCourse></PanelCourse>}
        </div>
      </div>
      <div className="pt-4 pb-4 bg-light" style={{ marginBottom: "-2rem" }}>
        <p className="text-center paragraph-text">
          © {new Date().getFullYear()} - Red Universitaria de Innovación. Todos
          los derechos reservados.
        </p>
      </div>
    </div>
  );
};

export default Panel;
