import img1 from "../../assets/img/koica/01.webp";
import img2 from "../../assets/img/koica/02.webp";
import img3 from "../../assets/img/koica/03.webp";
import img4 from "../../assets/img/koica/04.webp";
import img5 from "../../assets/img/koica/05.webp";
import img6 from "../../assets/img/koica/06.webp";
import img7 from "../../assets/img/koica/07.webp";
import img8 from "../../assets/img/koica/08.webp";
import img9 from "../../assets/img/koica/09.webp";
import img10 from "../../assets/img/koica/10.webp";
import img11 from "../../assets/img/koica/11.webp";
import img12 from "../../assets/img/koica/12.webp";
import img13 from "../../assets/img/koica/13.webp";
import img14 from "../../assets/img/koica/14.webp";
import img15 from "../../assets/img/koica/15.webp";
import img16 from "../../assets/img/koica/16.webp";
import img17 from "../../assets/img/koica/17.webp";
import img18 from "../../assets/img/koica/18.webp";
import img19 from "../../assets/img/koica/19.webp";
import img20 from "../../assets/img/koica/20.webp";

import udep from "../../assets/img/koica/universities/udep.webp";
import ucsm from "../../assets/img/koica/universities/ucsm.webp";
import ucsp from "../../assets/img/koica/universities/ucsp.webp";
import unalm from "../../assets/img/koica/universities/unalm.webp";
import unheval from "../../assets/img/koica/universities/unheval.webp";
import uni from "../../assets/img/koica/universities/uni.webp";
import unmsm from "../../assets/img/koica/universities/unmsm.webp";
import unsa from "../../assets/img/koica/universities/unsa.webp";
import unt from "../../assets/img/koica/universities/unt.webp";

import aldair from "../../assets/img/koica/coordinators/aldair.webp";
import alejandra from "../../assets/img/koica/coordinators/alejandra.webp";
import elizabeth from "../../assets/img/koica/coordinators/elizabeth.webp";
import enrique from "../../assets/img/koica/coordinators/enrique.webp";
import franco from "../../assets/img/koica/coordinators/franco.webp";
import gabriela from "../../assets/img/koica/coordinators/gabriela.webp";
import giussi from "../../assets/img/koica/coordinators/giussi.webp";
import marcos from "../../assets/img/koica/coordinators/marcos.webp";
import maria from "../../assets/img/koica/coordinators/maria.webp";
import orlando from "../../assets/img/koica/coordinators/orlando.webp";
import pablo from "../../assets/img/koica/coordinators/pablo.webp";
import solange from "../../assets/img/koica/coordinators/solange.webp";
import yon from "../../assets/img/koica/coordinators/yon.webp";

import concytec from "../../assets/img/koica/institutions/concytec.webp";
import gccei from "../../assets/img/koica/institutions/gccei.webp";
import inha from "../../assets/img/koica/institutions/inha.webp";
import koica from "../../assets/img/koica/institutions/koica.webp";
import kpsl from "../../assets/img/koica/institutions/kpsl.webp";
import n15 from "../../assets/img/koica/institutions/n15.webp";

export const koicaImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
];

export const nodes = [
  // North
  {
    universities: [udep, unt, unheval],
    coordinators: [
      {
        name: "Aldair Sayhua Flores",
        image: aldair,
        position: "Coordinador de Incubación de empresas - UDEP",
      },
      {
        name: "Marcos Agurto Evangelista",
        image: marcos,
        position: "Coordinador de Incubación de empresas - UNT",
      },
      {
        name: "Gabriela Tarazona Esparza",
        image: gabriela,
        position: "Coordinador de Transferencia Tecnológica - UNHEVAL",
      },
      {
        name: "Yon Bander Huacdcanjulca Avila",
        image: yon,
        position: "Coordinador de Transferencia Tecnológica - UNT",
      },
    ],
  },
  // Center
  {
    universities: [unmsm, uni, unalm],
    coordinators: [
      {
        name: "Franco Andre Yactayo Elias",
        image: franco,
        position: "Coordinador de Transferencia Tecnológica - UNI",
      },
      {
        name: "Elizabeth Rodriguez",
        image: elizabeth,
        position: "Coordinador de Transferencia Tecnológica - UNALM",
      },
      {
        name: "Enrique García Medina",
        image: enrique,
        position: "Coordinador de Transferencia Tecnológica - UNMSM",
      },
      {
        name: "Solange Ruiz Huajan",
        image: solange,
        position: "Coordinador de Incubación de empresas - UNI",
      },
    ],
  },
  // South

  {
    universities: [ucsm, unsa, ucsp],
    coordinators: [
      {
        name: "Giussi Alba Huarcaya Lizarraga",
        image: giussi,
        position: "Coordinador de Transferencia Tecnológica - UNSA",
      },
      {
        name: "Orlando Mendoza",
        image: orlando,
        position: "Coordinador de Incubación de Empresas - UNSA",
      },
      {
        name: "Pablo Cesar Pacheco Postigo",
        image: pablo,
        position: "Coordinador de Transferencia Tecnológica - UCSP",
      },
      {
        name: "Alejandra Muñoz",
        image: alejandra,
        position: "Coordinador de Incubación de Empresas - UCSM",
      },
      {
        name: "María Fernandez",
        image: maria,
        position: "Coordinador de Transferencia Tecnológica - UCSM",
      },
    ],
  },
];

export const institutions = {
  korea: [koica, inha, gccei, n15],
  peruvian: [concytec, kpsl],
};
