import { useEffect, useRef, useState } from "react";
import useNews from "../../hooks/useNews";
import { useDispatch } from "react-redux";
import {
  fetchNewsData,
  removeNewsData,
  saveNewsData,
  updateNewsData,
} from "../../redux/thunks/newsThunk";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import {
  fetchImageData,
  removeImageData,
  saveImageData,
} from "../../redux/thunks/imageThunk";
import Swal from "sweetalert2";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import Joi from "joi";
import Loader from "../common/loader";

const PanelNews = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [formNewsData, setFormNewsData] = useState({
    title: "",
    category: "",
    author: "",
    description: "",
    date: "",
    user: localStorage.getItem("email"),
    image: null,
    imageKey: null,
    fileImage: null,
  });

  const inputFileRef = useRef(null);
  const { loading, newsData, error } = useNews();
  const [selectedRow, setSelectedRow] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = newsData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Panel - Noticias";
    Object.keys(newsData).length === 0 && dispatch(fetchNewsData());
  }, []);

  const newsItemSchema = Joi.object({
    title: Joi.string().required(),
    category: Joi.string().required(),
    author: Joi.string().required(),
    description: Joi.string().required(),
    date: Joi.date().required(),
    user: Joi.string().allow(),
    image: Joi.optional(),
    imageKey: Joi.optional(),
    fileImage: Joi.optional(),
    urlImage: Joi.optional(),
    // Add other validation rules as needed
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await newsItemSchema.validateAsync(formNewsData, { abortEarly: false });
      if (selectedRow === null) {
        // Add news
        console.log(formNewsData);
        if (formNewsData.fileImage != null) {
          const formData = new FormData();
          formData.append("image", formNewsData.fileImage);

          const imgResponse = await dispatch(saveImageData(formData));
          if (imgResponse instanceof Error || imgResponse.error) {
            throw new Error("La imagen no pudo subirse a la nube.");
          }
          formNewsData.image = imgResponse.content;
          const fetchImgresponse = await dispatch(
            fetchImageData(formNewsData.image)
          );
          if (fetchImgresponse instanceof Error || fetchImgresponse.error) {
            throw new Error("La imagen no pudo cargarse.");
          }
          formNewsData.urlImage = fetchImgresponse.payload.content;
        }
        await dispatch(saveNewsData(formNewsData));
      } else {
        // Update news
        formNewsData.id = selectedRow;

        if (formNewsData.fileImage !== null) {
          const removeImgResponse = await dispatch(
            removeImageData(formNewsData.imageKey)
          );
          if (removeImgResponse instanceof Error || removeImgResponse.error) {
            throw new Error("La imagen no pudo eliminarse de la nube.");
          }

          const formData = new FormData();
          formData.append("image", formNewsData.fileImage);

          const saveImgResponse = await dispatch(saveImageData(formData));
          if (saveImgResponse instanceof Error || saveImgResponse.error) {
            throw new Error("La imagen no pudo eliminarse de la nube.");
          }
          formNewsData.image = saveImgResponse.payload.content;

          const fetchImgresponse = await dispatch(
            fetchImageData(formNewsData.image)
          );
          if (fetchImgresponse instanceof Error || fetchImgresponse.error) {
            throw new Error("La imagen no pudo subirse a la nube.");
          }
          formNewsData.urlImage = fetchImgresponse.payload.content;
        } else {
          formNewsData.image = null;
          formNewsData.urlImage = null;
        }
        await dispatch(updateNewsData(formNewsData));
      }

      cleanFields();
      await Swal.fire({
        icon: "success",
        title: "¡Datos Guardados!",
        text: "Los datos se han guardado exitosamente.",
      });
    } catch (error) {
      const validationErrors = error?.details?.map((detail) => detail.message);
      let message = error.message;
      if (validationErrors) {
        message = "Algunos campos son obligatorios.";
      }
      await Swal.fire({
        icon: "error",
        title: "Error al Guardar Datos",
        text: message,
      });
    }
    dispatch(fetchNewsData());
  };

  const handleDelete = async (id) => {
    try {
      // Show confirmation dialog before deleting
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Proceed with deletion if user confirms
      if (result.isConfirmed) {
        await dispatch(removeNewsData(id));
        if (formNewsData.imageKey)
          await dispatch(removeImageData(formNewsData.imageKey));

        cleanFields();
        await Swal.fire({
          icon: "success",
          title: "¡Datos Eliminados!",
          text: "Los datos se han eliminado exitosamente.",
        });
      }
    } catch (error) {
      // Handle errors
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error al eliminar la noticia.",
      });
    }
    dispatch(fetchNewsData());
  };

  const handleUpdate = (row) => {
    setFormNewsData({
      title: row.title,
      category: row.category,
      author: row.author,
      description: row.description,
      date: row.date,
      image: row.urlImage,
      imageKey: row.image,
      fileImage: null,
    });
    setSelectedRow(row.id);
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    cleanFields();
  };

  const cleanFields = () => {
    setFormNewsData({
      title: "",
      category: "",
      author: "",
      description: "",
      date: "",
      image: null,
      imageKey: null,
      fileImage: null,
    });
    setSelectedRow(null);
    inputFileRef.current.value = null;
  };

  const clearImage = () => {
    setFormNewsData((prevData) => ({
      ...prevData,
      fileImage: null,
      image: null,
      imageKey: null,
    }));
    // Clear the input file value to allow selecting the same file again
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };
  
  let filteredNewsData = [];

  if (Array.isArray(newsData)) {
    filteredNewsData = newsData.filter((event) => {
      const title = event.title ? event.title.toLowerCase() : "";
      const description = event.description
        ? event.description.toLowerCase()
        : "";
      const search = searchTerm.toLowerCase();

      return title.includes(search) || description.includes(search);
    });
  }

  return (
    <div>
      <div className="px-3 pt-5 container">
        {loading && <Loader />}
        <p className="title-text text-center pt-5">Noticias</p>
        <div className="row">
          <div className="col-12 col-xl-12 px-4 py-4">
            <form className="paragraph-text">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Title"
                  value={formNewsData.title}
                  onChange={(e) =>
                    setFormNewsData({ ...formNewsData, title: e.target.value })
                  }
                />
                <label htmlFor="title">Título</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  placeholder="Category"
                  value={formNewsData.category}
                  onChange={(e) =>
                    setFormNewsData({
                      ...formNewsData,
                      category: e.target.value,
                    })
                  }
                />
                <label htmlFor="category">Categoría</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="author"
                  placeholder="Autor"
                  value={formNewsData.author}
                  onChange={(e) =>
                    setFormNewsData({ ...formNewsData, author: e.target.value })
                  }
                />
                <label htmlFor="category">Autor</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control textarea"
                  id="description"
                  placeholder="Description"
                  rows="4"
                  value={formNewsData.description}
                  onChange={(e) =>
                    setFormNewsData({
                      ...formNewsData,
                      description: e.target.value,
                    })
                  }
                  style={{
                    height: "20rem",
                  }}
                />
                <label htmlFor="description">Descripción</label>
              </div>
              <div className="form-floating mb-3 w-25">
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  placeholder="Date"
                  value={formNewsData.date}
                  onChange={(e) =>
                    setFormNewsData({ ...formNewsData, date: e.target.value })
                  }
                />
                <label htmlFor="date">Fecha </label>
              </div>
              <div className="row d-flex d-flex--center pb-4">
                <div className="col-12 col-lg-6 mb-3">
                  <input
                    ref={inputFileRef}
                    type="file"
                    className="form-control"
                    id="image"
                    accept="image/*"
                    placeholder="Image"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      setFormNewsData((prevData) => ({
                        ...prevData,
                        fileImage: selectedFile,
                        image: selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : null,
                      }));
                      console.log(newsData.image);
                    }}
                  />
                </div>
                <div className="col-12 col-lg-1 mb-3">
                  {formNewsData.image && (
                    <div>
                      <button
                        type="button"
                        className="btn"
                        onClick={clearImage}
                      >
                        <AiFillDelete className="row-icon" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-12 col-lg-4">
                  {formNewsData.image && (
                    <img
                      className="xs-img"
                      src={formNewsData.image}
                      alt="s3image"
                    />
                  )}
                </div>
              </div>
              <div className="d-flex d-flex--center d-flex--direction-row gap-md">
                <button
                  type="submit"
                  className="btn btn-primary"
                  aria-label="Guardar Noticia"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
                <button
                  className="btn btn-primary"
                  aria-label="Cancelar"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div className="row px-4">
            <div className="col-md-8 ">
              <ul className="pagination m-auto">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    aria-label="Pagina anterior"
                    disabled={currentPage === 1}
                  >
                    <FaArrowAltCircleLeft />
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      aria-label="Pagina actual"
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    aria-label="Pagina siguiente"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <FaArrowAltCircleRight />
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-4 form-floating ">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por título"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              <label htmlFor="description">Buscar ...</label>
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-12 px-4 py-4 panel-container--sm">
            {filteredNewsData && newsData.length > 0 ? (
              <div>
                <table className="table table-bordered paragraph-text">
                  <thead className="table-header ">
                    <tr>
                      <th scope="col">Titulo</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Opciones</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {filteredNewsData
                      .slice(startIndex, endIndex)
                      .map((item) => (
                        <tr
                          key={item.id}
                          className={
                            selectedRow === item.id ? "selected-row" : ""
                          }
                        >
                          <td>{item.title}</td>
                          <td>{item.category}</td>
                          <td>
                            <div className="d-flex d-flex--center d-flex--direction-row ">
                              <button
                                className="btn "
                                aria-label="Editar noticia"
                                onClick={() => handleUpdate(item)}
                              >
                                <AiFillEdit
                                  className={
                                    selectedRow === item.id
                                      ? "row-icon"
                                      : "secondary-small-icon"
                                  }
                                />
                              </button>
                              <button
                                className="btn "
                                aria-label="Eliminar noticia"
                                onClick={() => handleDelete(item.id)}
                              >
                                <AiFillDelete
                                  className={
                                    selectedRow === item.id
                                      ? "row-icon"
                                      : "secondary-small-icon"
                                  }
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="subtitle-text">No hay noticias disponibles.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelNews;
