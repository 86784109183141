import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../config/axiosInstance";

export const fetchImageData = createAsyncThunk(
  "image/fetchImageData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`image/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveImageData = createAsyncThunk(
  "image/saveImageData",
  async (image, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("image/", image, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeImageData = createAsyncThunk(
  "image/removeImageData",
  async (key, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`image/${key}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
