import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceNoAuth } from "../../config/axiosInstance";

export const fetchNewsData = createAsyncThunk(
  "news/fetchNewsData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get("news/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveNewsData = createAsyncThunk(
  "news/saveNewsData",
  async (news, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("news/", news);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeNewsData = createAsyncThunk(
  "news/removeNewsData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`news/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateNewsData = createAsyncThunk(
  "news/updateNewsData",
  async (news, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(`news/${news.id}`, news);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchNewsDataById = createAsyncThunk(
  "news/fetchNewsDataById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get(`news/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
