import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceNoAuth } from "../../config/axiosInstance";

export const sendEmailData = createAsyncThunk(
  "email/sendEmailData",
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.post("/email/", email);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emailCaptchaData = createAsyncThunk(
  "email/emailCaptchaData",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.post("/captcha/", token);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
