import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSubscriberData,
  removeSubscriberData,
  saveSubscriberData,
  updateSubscriberData,
} from "../thunks/subscriberThunk";

const initialState = {
  loading: false,
  error: {},
  subscriberData: [],
};

export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchSubscriberData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubscriberData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.subscriberData = action.payload.data;
    });
    builder.addCase(fetchSubscriberData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.subscriberData = {};
    });
    builder.addCase(removeSubscriberData.fulfilled, (state, action) => {
      state.subscriberData = state.subscriberData.filter(
        (item) => item.id !== action.payload.data.id
      );
    });
    builder.addCase(saveSubscriberData.fulfilled, (state, action) => {
      if (!action.payload.error) state.subscriberData.push(action.payload.data);
    });
    builder.addCase(updateSubscriberData.fulfilled, (state, action) => {
      const { id, ...updatedItem } = action.payload.data;
      const index = state.subscriberData.findIndex(
        (subscriber) => subscriber.id === id
      );
      state.subscriberData[index] = {
        ...state.subscriberData[index],
        ...updatedItem,
      };
    });
  },
});

export default subscriberSlice.reducer;
