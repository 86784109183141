import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCourseData,
  removeCourseData,
  saveCourseData,
  updateCourseData,
  fetchCourseDataById,
} from "../thunks/courseThunk";

const initialState = {
  loading: false,
  error: {},
  courseData: [],
};

export const courseSlice = createSlice({
  name: "course",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchCourseData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.courseData = action.payload.data;
    });
    builder.addCase(fetchCourseData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.courseData = [];
    });

    builder.addCase(removeCourseData.fulfilled, (state, action) => {
      console.log(state.courseData);
      state.courseData = state.courseData.filter(
        (item) => item.id !== action.payload.data.id
      );
      console.log(state.courseData);
    });

    builder.addCase(saveCourseData.fulfilled, (state, action) => {
      if (!action.payload.error) state.courseData.push(action.payload.data);
    });

    builder.addCase(updateCourseData.fulfilled, (state, action) => {
      const { id, ...updatedItem } = action.payload.data;

      const index = state.courseData.findIndex((course) => course.id === id);
      state.courseData[index] = { ...state.courseData[index], ...updatedItem };
    });

    builder.addCase(fetchCourseDataById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseDataById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
    });
    builder.addCase(fetchCourseDataById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
    });
  },
});

export default courseSlice.reducer;
