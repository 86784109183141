import { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.webp";
import List from "./list";
import {
  BsList,
  BsXCircle,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";

const Header = ({ elements, paths }) => {
  const header = useRef();
  const nav = useRef();
  const sidebar = useRef();
  const menu = useRef();

  const documentScroll = () => {
    header.current?.classList.toggle("header--scroll", window.scrollY > 0);
    nav.current?.classList.toggle("nav--scroll", window.scrollY > 0);
  };

  const handleClickShowMenu = () => {
    header.current.classList.add("header--move-left");
    sidebar.current.classList.add("sidebar--show");
    menu.current.classList.add("icon--hide");
    menu.current.classList.remove("icon--display");
  };

  const handleClickHideMenu = () => {
    header.current.classList.remove("header--move-left");
    sidebar.current.classList.remove("sidebar--show");
    menu.current.classList.add("icon--display");
    menu.current.classList.remove("icon--hide");
    window.scrollTo(0, 0);
  };

  document.addEventListener("scroll", documentScroll);

  return (
    <header className="header" ref={header}>
      <nav className="nav" ref={nav}>
        <div className="container d-flex d-flex--space-between">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="nav__logo"
              aria-label="Logo Cabecera"
              width="100%"
              height="100%"
            />
          </Link>
          <div
            className="sidebar sidebar--close"
            ref={sidebar}
            onClick={handleClickHideMenu}
          >
            <List
              className="list list--sidebar list--right-sidebar d-flex d-flex--center d-flex--direction-responsive gap-md"
              handleClickList={(e) => e.stopPropagation()}
              handleClickNavLink={handleClickHideMenu}
              elements={elements}
              paths={paths}
            />
            <button
              className="icon icon--close"
              onClick={handleClickHideMenu}
              aria-label="Close Menu"
            >
              <BsXCircle />
            </button>
          </div>
          <div className="d-flex d-flex--center gap-md">
            <Link
              className="link-social-media"
              to={{
                pathname: "//www.facebook.com/reduniversitariadeinnovacion",
              }}
              aria-label="Facebook"
              target="_blank"
            >
              <BsFacebook />
            </Link>
            <Link
              className="link-social-media"
              to={{ pathname: "//www.instagram.com/rui.peru/" }}
              aria-label="Instagram"
              target="_blank"
            >
              <BsInstagram />
            </Link>
            <Link
              className="link-social-media"
              to={{
                pathname: "//www.youtube.com/channel/UCz_G74cODaosWOhQlVObozQ",
              }}
              aria-label="Youtube"
              target="_blank"
            >
              <BsYoutube />
            </Link>
          </div>
          <div className="d-flex d-flex--center gap-md">
            <button
              ref={menu}
              className="icon icon--menu icon--display"
              onClick={handleClickShowMenu}
              aria-label="Show Menu"
            >
              <BsList />
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
