import { createSlice } from "@reduxjs/toolkit";
import { sendEmailData, emailCaptchaData } from "../thunks/emailThunk";

const initialState = {
  loading: false,
  error: {},
  emailData: {},
};

export const emailSlice = createSlice({
  name: "email",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(sendEmailData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEmailData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.emailData = action.payload.data;
    });
    builder.addCase(sendEmailData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.emailData = {};
    });

    builder.addCase(emailCaptchaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(emailCaptchaData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.emailData = action.payload.data;
    });
    builder.addCase(emailCaptchaData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.emailData = {};
    });
  },
});

export default emailSlice.reducer;
