import { useEffect, useState } from "react";

const Countdown = ({ deadline, title }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    setSeconds(Math.floor((time / 1000) % 60));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
  };

  useEffect(() => {
    getTimeUntil(deadline);
  });

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);
  }, [deadline]);

  const leading0 = (num) => {
    if (num <= 0) return "0";
    return num < 10 ? "0" + num : num;
  };

  return (
    <div className="container count-down-box py-5">
      <p className="light-subtitle-text text-center display-5">
        Nuestro próximo programa <strong>{title} </strong>comenzará pronto ...
      </p>

      <div className="row justify-content-center ">
        <div className="col-12 col-xl-3 count-down d-flex d-flex--center d-flex--direction-column">
          <div className="title-text-xl">{leading0(days)}</div>
          <div className="light-subtitle-text">Días</div>
        </div>
        <div className="col-12 col-xl-3 count-down d-flex d-flex--center d-flex--direction-column">
          <div className="title-text-xl">{leading0(hours)}</div>
          <div className="light-subtitle-text">Horas</div>
        </div>
        <div className="col-12 col-xl-3 count-down d-flex d-flex--center d-flex--direction-column">
          <div className="title-text-xl">{leading0(minutes)}</div>
          <div className="light-subtitle-text">Minutos</div>
        </div>
        <div className="col-12 col-xl-3 count-down d-flex d-flex--center d-flex--direction-column">
          <div className="title-text-xl">{leading0(seconds)}</div>
          <div className="light-subtitle-text">Segundos</div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
