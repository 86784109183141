import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNewsData,
  removeNewsData,
  saveNewsData,
  updateNewsData,
  fetchNewsDataById,
} from "../thunks/newsThunk";

const initialState = {
  loading: false,
  error: {},
  newsData: [], // Changed from {} to []
  post: {},
};

export const newsSlice = createSlice({
  name: "news",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchNewsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNewsData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.newsData = action.payload.data;
    });
    builder.addCase(fetchNewsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.newsData = {};
    });

    builder.addCase(removeNewsData.fulfilled, (state, action) => {
      state.newsData = state.newsData.filter(
        (item) => item.id !== action.payload.id
      );
    });

    builder.addCase(saveNewsData.fulfilled, (state, action) => {
      state.newsData.push(action.payload);
    });
    builder.addCase(updateNewsData.fulfilled, (state, action) => {
      const { id, ...updatedItem } = action.payload.data;

      const index = state.newsData.findIndex((news) => news.id === id);
      state.newsData[index] = { ...state.newsData[index], ...updatedItem };
    });

    builder.addCase(fetchNewsDataById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNewsDataById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.post = action.payload.data;
    });
    builder.addCase(fetchNewsDataById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.post = {};
    });
  },
});

export default newsSlice.reducer;
