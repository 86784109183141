import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceNoAuth } from "../../config/axiosInstance";

export const fetchCourseData = createAsyncThunk(
  "course/fetchCourseData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get("course/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveCourseData = createAsyncThunk(
  "course/saveCourseData",
  async (course, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("course/", course);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeCourseData = createAsyncThunk(
  "course/removeCourseData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`course/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCourseData = createAsyncThunk(
  "course/updateCourseData",
  async (course, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(`course/${course.id}`, course);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCourseDataById = createAsyncThunk(
  "course/fetchCourseDataById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get(`course/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
