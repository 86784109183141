import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useLogin from "../../hooks/useLogin";
import { fetchLoginData } from "../../redux/thunks/loginThunk";
import Cookies from "js-cookie";

const Login = () => {
  let navigate = useNavigate();
  const { loading, loginData, error } = useLogin();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.title = "Panel - Login";
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = await dispatch(fetchLoginData({ email, password }));
    if (auth.error === undefined) {
      localStorage.setItem("email", email);
      Cookies.set("access_token", auth.payload.access); // set access token cookie to expire in 1 day
      Cookies.set("refresh_token", auth.payload.refresh); // set refresh token cookie to expire in 30 days

      let path = "../panel";
      navigate(path, { state: { email: email } });
    }
  };

  return (
    <div className="">
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div className="p-4 bg-white rounded shadow ">
          <form>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo-img"
                  aria-label="Logo RUI"
                />
              </Link>
            </div>
            <h5 className="mb-3 text-center">Por favor inicie sesión</h5>
            <div className="form-floating mb-2">
              <input
                className="form-control"
                id="floatingInput"
                placeholder="Usuario"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <label htmlFor="floatingInput">E-mail</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Contraseña"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <label htmlFor="floatingPassword">Contraseña</label>
            </div>
            {error && (
              <div className="alert alert-danger" role="alert">
                <div>Contraseña o usuario incorrecto</div>
                <div>Por favor, vuelva a intentarlo</div>
              </div>
            )}

            <button
              className="btn btn-primary w-100"
              type="submit"
              onClick={handleLogin}
              aria-label="Acceder a Panel"
            >
              Iniciar sesión
            </button>
            <p className="mb-0 text-muted mt-3 text-center">
              © {new Date().getFullYear()} - Red Universitaria de Innovación.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
