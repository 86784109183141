import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Countdown from "../components/common/countdown";
import {
  koicaImages,
  nodes,
  institutions,
} from "../components/koica/imageGallery";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Koica = () => {
  const [deadline, setDeadline] = useState("November 23, 2023 9:00:00");

  ReactGA.initialize(process.env.REACT_APP_GA4_TRACKING_ID);
  useEffect(() => {
    document.title = "RUI - Peru Innovation Startup Festival";
    window.scrollTo(0, 0);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Peru Innovation Startup Festival",
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="pt-5">
      <div className="pt-5 py-4">
        <div className="text-center">
          <section className="koica-section d-flex d-flex--center"></section>
          <section className="container pt-5">
            <p className="paragraph-text">
              “Koica Innovation Perú Startup Festival 2023”, es un evento del
              Proyecto para Fortalecer la Innovación y el Emprendimiento basado
              en TICs, de KOICA, Concytec y la Red universitaria de Innovación
              el cual tiene como objetivo presentar los resultados obtenidos
              durante estos tres años de duración del proyecto.
            </p>
            <div className="py-5">
              <Countdown
                deadline={deadline}
                title={"Peru Innovation Startup Festival"}
              />
            </div>
            <div className="row m-2">
              <div className="col-12 col-md-7">
                <Carousel>
                  {koicaImages.map((image, index) => (
                    <div key={index}>
                      <img alt="" src={image} />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="p-3 col-12 col-md-5 d-flex d-flex--center d-flex--direction-column gap-md">
                <div>
                  <p className="paragraph-text">
                    <strong>Fecha:</strong> 23 de noviembre{" "}
                  </p>
                  <p className="paragraph-text">
                    <strong>Lugar:</strong> El Pardo Doubletree by Hilton{" "}
                  </p>
                  <p className="paragraph-text">
                    <strong>Hora:</strong> De 9 am a 5 pm
                  </p>
                </div>
                <div>
                  <a
                    href="https://drive.google.com/file/d/1l3-kKIxfjyYLspfgWNIYFeSrPE3IPdps/view"
                    className="btn btn-primary mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Agenda
                  </a>
                  <a
                    href="https://startupleague-kpsl.com/#"
                    className="btn btn-primary mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ver más
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section></section>
          <section className="container py-5">
            <Tabs>
              <TabList className="title-text">
                <Tab>Nodo Norte</Tab>
                <Tab>Nodo Centro</Tab>
                <Tab>Nodo Sur</Tab>
              </TabList>
              {nodes.map((node, index) => (
                <TabPanel key={index}>
                  <div>
                    <div className="py-4">
                      <p className="subtitle-text">Universidades</p>
                      <div className="row py-3">
                        {node.universities.map((image, index) => (
                          <div key={index} className="col-12 col-md-4 my-4">
                            <img
                              className="logo-institution"
                              alt=""
                              src={image}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="py-4"></div>
                      <p className="subtitle-text">Coordinadores</p>
                      <div className="row d-flex d-flex--center">
                        {node.coordinators.map((coordinator, index) => (
                          <div
                            key={index}
                            className="col-12 col-md-6 col-lg-4 col-xl-4 d-flex d-flex--center py-3"
                          >
                            <div className="card card__person">
                              <div className="card-img d-flex d-flex--center mt-4">
                                <img
                                  src={coordinator.image}
                                  className="circular-img"
                                  alt=""
                                />
                              </div>
                              <div className="card-body d-flex d-flex--center">
                                <strong className="paragraph-text">
                                  {coordinator.name}
                                </strong>
                              </div>
                              <div className="card-footer">
                                <p className="secondary-paragraph-text text-center">
                                  {coordinator.position}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>{" "}
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </section>

          <section className="container py-4">
            <p className="title-text">Agradecimientos</p>
            <p className="subtitle-text py-2">Instituciones Coreanas</p>
            <div className="row d-flex d-flex--center  py-4">
              {institutions.korea.map((image, index) => (
                <div
                  key={index}
                  className="col-12 col-xs-6 col-md-4 col-lg-3 m-4"
                >
                  <img className="logo-institution" alt="" src={image} />
                </div>
              ))}
            </div>
            <p className="subtitle-text py-2">Instituciones Peruanas</p>
            <div className="row d-flex d-flex--center  py-4">
              {institutions.peruvian.map((image, index) => (
                <div
                  key={index}
                  className="col-12 col-xs-6 col-md-4 col-lg-3 m-4"
                >
                  <img className="logo-institution" alt="" src={image} />
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Koica;
