import { mainRoutes } from "./routes/mainRoutes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ReactGA from "react-ga4";
import { useEffect } from "react";
function App() {
  ReactGA.initialize(process.env.REACT_APP_GA4_TRACKING_ID);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "RUI",
    });
  }, []);
  return (
    <Provider store={store}>
      <RouterProvider router={mainRoutes}></RouterProvider>;
    </Provider>
  );
}
export default App;
