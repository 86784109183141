import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CardNews = ({ title, content, date, maxChars, id }) => {
  const [expanded, setExpanded] = useState(false);
  let navigate = useNavigate();

  const truncatedContent = expanded ? content : content.slice(0, maxChars);

  const handleSeeMoreClick = () => {
    if (!expanded) {
      navigate(`/noticias/${id}`, { state: { id: id } });
    } else {
      setExpanded(true);
    }
  };

  return (
    <div className="card card__news">
      <div className="card-header">
        <button className="btn-secondary py-1" onClick={handleSeeMoreClick}>
          <h5 className="title-link-download">{title}</h5>
        </button>
      </div>
      <div className="card-body p-4">
        <p className="paragraph-text card-text py-1">{truncatedContent}... </p>
        {!expanded && content.length > maxChars && (
          <button className="btn-secondary " onClick={handleSeeMoreClick}>
            <p className="paragraph-text pt-3 link-download">Ver más ...</p>
          </button>
        )}
      </div>
      <div className="card-footer d-flex d-flex--center">
        <p className="secondary-paragraph-text text-center">
          {new Date(date).toLocaleDateString("es-ES", {
            month: "long", // "June"
            day: "numeric", // "30"
            year: "numeric", // "2023"
          })}
        </p>
      </div>
    </div>
  );
};

export default CardNews;
