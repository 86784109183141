import axios from "axios";
import Cookies from "js-cookie";

const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`, // add the access token as a Bearer token in the Authorization header
    "Content-Type": "application/json", // set the content type to JSON
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get("refresh_token");
      if (!refreshToken) {
        // window.location.href = "/acceso";
        return Promise.reject(error);
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}refresh/`,
          {
            refresh: refreshToken,
          }
        );
        const { access: newAccessToken } = response.data;
        Cookies.set("access_token", newAccessToken);
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        if (
          error.response.status === 401 &&
          window.location.href.includes("panel")
        )
          console.log("CATCH");

        window.location.href = "/acceso";
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosInstanceNoAuth };
