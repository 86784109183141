import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchNewsDataById } from "../../redux/thunks/newsThunk";
import { Link } from "react-router-dom";

const Post = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news.post);

  useEffect(() => {
    dispatch(fetchNewsDataById(id));
  }, [dispatch, id]);

  return (
    <div className="container mt-5">
      {console.log(news.description)}

      <div className="row p-5">
        <div className="col-md-3 col-12 pe-4">
          {/* Left column: Date and Author */}
          {news && (
            <div className="mb-4">
              <p className="paragraph-text">
                <strong>🤵 Autor(a): </strong> {news.author || "RUI"}
              </p>
              <p className="paragraph-text">
                <strong>🤵 Categoría: </strong> {news.category || "Educación"}
              </p>
              <p className="paragraph-text">
                <strong>📅 Fecha: </strong>
                {new Date(news.date).toLocaleDateString("es-ES", {
                  month: "long", // "June"
                  day: "numeric", // "30"
                  year: "numeric", // "2023"
                })}
              </p>
              <Link className="link" to="/noticias" aria-label="Ver Noticias">
                <p className="paragraph-text ">⬅️ Volver a las Noticias</p>
              </Link>
            </div>
          )}
        </div>
        <div
          className="col-md-9 col-12"
          style={{ borderLeft: "4px solid #0C96D4" }}
        >
          {/* Right column: Image and Description */}
          {news ? (
            <div className="ps-5">
              {news.urlImage && (
                <div className="d-flex d-flex--center d-flex--direction-column ">
                  <img
                    src={news.urlImage}
                    alt={news.title}
                    className="mb-4 img-fluid"
                  />
                </div>
              )}
              <h2 className="py-2 subtitle-text">{news.title}</h2>
              <p className="paragraph-text py-4 text-break pre-line">
                {news.description}
              </p>{" "}
            </div>
          ) : (
            <p>Cargando Noticias...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Post;
