import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceNoAuth } from "../../config/axiosInstance";

export const fetchEventData = createAsyncThunk(
  "event/fetchEventData",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get(`event/${filter}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveEventData = createAsyncThunk(
  "event/saveEventData",
  async (event, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("event/", event);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeEventData = createAsyncThunk(
  "event/removeEventData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`event/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEventData = createAsyncThunk(
  "event/updateEventData",
  async (event, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(`event/${event.id}`, event);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
