import { createAsyncThunk } from "@reduxjs/toolkit";
import {axiosInstance} from "../../config/axiosInstance";
import Cookies from "js-cookie";

export const fetchLoginData = createAsyncThunk(
  "login/fetchLoginData",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/login/", {
        email: email,
        password: password,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchLogoutData = createAsyncThunk(
  "login/fetchLogoutData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/logout/", {
        refresh_token: Cookies.get("refresh_token"),
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);
