import { createSlice } from "@reduxjs/toolkit";
import {
  fetchImageData,
  removeImageData,
  saveImageData,
} from "../thunks/imageThunk";

const initialState = {
  loading: false,
  error: {},
  imageData: {},
};

export const imageSlice = createSlice({
  name: "image",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchImageData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchImageData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.imageData = action.payload.data;
    });
    builder.addCase(fetchImageData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.imageData = {};
    });
    builder.addCase(removeImageData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.imageData = action.payload.data;
    });
    builder.addCase(saveImageData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.imageData = action.payload.data;
    });
  },
});

export default imageSlice.reducer;
