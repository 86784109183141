import { createSlice } from "@reduxjs/toolkit";
import { fetchLoginData } from "../thunks/loginThunk";

const initialState = {
  loading: false,
  error: undefined,
  loginData: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoginData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLoginData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.loginData = action.payload.data;
    });
    builder.addCase(fetchLoginData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.loginData = {};
    });
  },
});

export default loginSlice.reducer;
