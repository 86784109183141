import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  return (
    <div>
      <div className="row pt-3">
        <div className="col-12 d-flex d-flex--center gap-md">
          <Link
            to={"//www.facebook.com/reduniversitariadeinnovacion"}
            target="_blank"
            aria-label="Facebook"
          >
            <BsFacebook className="icon icon--social-media" />
          </Link>
          <Link
            to={"//www.instagram.com/rui.peru/"}
            target="_blank"
            aria-label="Instagram"
          >
            <BsInstagram className="icon icon--social-media" />
          </Link>
          <Link
            to={"//www.youtube.com/channel/UCz_G74cODaosWOhQlVObozQ"}
            target="_blank"
            aria-label="Youtube"
          >
            <BsYoutube className="icon icon--social-media" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
