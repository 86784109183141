import { configureStore } from "@reduxjs/toolkit";
import newsReducer from "./slices/newsSlice";
import loginReducer from "./slices/loginSlice";
import imageReducer from "./slices/imageSlice";
import emailReducer from "./slices/emailSlice";
import eventReducer from "./slices/eventSlice";
import courseReducer from "./slices/courseSlice";
import subscriberReducer from "./slices/subscriberSlice";

export const store = configureStore({
  reducer: {
    news: newsReducer,
    event: eventReducer,
    login: loginReducer,
    image: imageReducer,
    email: emailReducer,
    course: courseReducer,
    subscriber: subscriberReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
