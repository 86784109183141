import { createSlice } from "@reduxjs/toolkit";
import {
  fetchEventData,
  removeEventData,
  saveEventData,
  updateEventData,
} from "../thunks/eventThunk";

const initialState = {
  loading: false,
  error: {},
  eventData: {},
};

export const eventSlice = createSlice({
  name: "event",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchEventData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEventData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = {};
      state.eventData = action.payload.data;
    });
    builder.addCase(fetchEventData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data;
      state.eventData = {};
    });
    builder.addCase(removeEventData.fulfilled, (state, action) => {
      state.eventData = state.eventData.filter(
        (item) => item.id !== action.payload.data.id
      );
    });
    builder.addCase(saveEventData.fulfilled, (state, action) => {
      if (!action.payload.error) state.eventData.push(action.payload.data);
    });
    builder.addCase(updateEventData.fulfilled, (state, action) => {
      const { id, ...updatedItem } = action.payload.data;
      const index = state.eventData.findIndex((event) => event.id === id);
      state.eventData[index] = { ...state.eventData[index], ...updatedItem };
    });
  },
});

export default eventSlice.reducer;
