import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/mainLayout";
import News from "../pages/news";
import Login from "../components/common/login";
import Panel from "../components/common/panel";
import React, { lazy, Suspense } from "react";
import Post from "../components/news/post";
import Koica from "../pages/koica";

const Home = lazy(() => import("../pages/home"));
const Associates = lazy(() => import("../pages/associates"));
const Programs = lazy(() => import("../pages/programs"));
const Courses = lazy(() => import("../pages/course"));
const Events = lazy(() => import("../pages/events"));
const Contact = lazy(() => import("../pages/contact"));
const NotFound = lazy(() => import("../components/common/notFound"));

export const mainRoutes = createBrowserRouter([
  {
    path: "/",
    errorElement: <NotFound />,
    children: [
      {
        element: <MainLayout />,
        errorElement: <NotFound />,
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <Home />
              </Suspense>
            ),
          },
          {
            path: "/koica",
            element: (
              <Suspense>
                <Koica />
              </Suspense>
            ),
          },
          {
            path: "/asociados",
            element: (
              <Suspense>
                <Associates />
              </Suspense>
            ),
          },
          {
            path: "/cursos",
            element: (
              <Suspense>
                <Courses />
              </Suspense>
            ),
          },
          {
            path: "/programas",
            element: (
              <Suspense>
                <Programs />
              </Suspense>
            ),
          },
          {
            path: "/eventos",
            element: (
              <Suspense>
                <Events />
              </Suspense>
            ),
          },
          {
            path: "/noticias",
            element: <News />,
          },
          {
            path: "/noticias/:id", // Use a dynamic parameter for the news id
            element: (
              <Suspense>
                <Post />
              </Suspense>
            ),
          },
          {
            path: "/contacto",
            element: (
              <Suspense>
                <Contact />
              </Suspense>
            ),
          },
          {
            path: "/*",
            element: (
              <Suspense>
                <NotFound />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "/acceso",
        element: <Login />,
      },
      {
        path: "/panel",
        element: <Panel />,
      },
    ],
  },
]);
